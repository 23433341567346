<template>
    <grid-view 
        title="Листы вирт. клавиатуры"
        create
        create-title="Создать лист"
        url-create="/keyboard-list/create"
        url-update="/keyboard-list/update/:id"
        store-module="keyboard_list"
        :headers="[
            { text: 'Название', value: 'title', width: 1, sortable: true },
            { text: 'Обновлено', value: 'updated_at', width: 1, sortable: true },
            { text: '', value: 'edit', width: 1, sortable: false }
        ]"
        :format="formatItems"
    />
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'KeyboardListIndex',
    components: { GridView },
    methods: {
        formatItems (items) {
            return _.map(items, (item) => {
                
                if (item.updated_at) {

                    const dateUpdatedAt = new Date(item.updated_at * 1000);
                    
                    item.updated_at = _.join([
                        dateUpdatedAt.getDate(),
                        (dateUpdatedAt.getMonth() < 9 ? '0' : '') + (dateUpdatedAt.getMonth() + 1).toString(),
                        dateUpdatedAt.getFullYear()
                    ], '.');
                }

                return item;
            })
        } 
    }
}
</script>